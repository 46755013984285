<template>
  <div>
    <el-row :gutter="20">
      <el-select v-model="searchForm.dictionaryType" placeholder="分类">
        <el-option value label="全部">全部</el-option>
        <el-option
          v-for="dict in dicts"
          :key="dict.Nub"
          :value="dict.Nub"
          :label="dict.label"
          >{{ dict.label }}</el-option
        >
      </el-select>
      <el-button
        style="margin-left:10px"
        type="primary"
        icon="el-icon-search"
        @click="getDictList()"
        >查询</el-button
      >
      <el-button @click="resetSearch">重置</el-button>
      <el-row>
        <el-button
          style="margin-top:10px"
          type="primary"
          icon="el-icon-plus"
          @click="doAdd"
          >新增字典数据</el-button
        >
      </el-row>
    </el-row>
    <el-table
      v-loading="tableLoading"
      :data="tableData"
      border
      stripe
      style="width: 100%;margin:20px 0"
      :header-cell-style="{ background: '#f8f8f9' }"
    >
      <el-table-column type="index" label="序号" width="60" />
      <el-table-column prop="dictionaryType" label="字典类型" min-width="120">
        <template slot-scope="{ row }">
          {{
            row.dictionaryType | dictionaryTypeFilter(row.dictionaryType, this)
          }}
        </template>
      </el-table-column>
      <el-table-column prop="dictionaryValue" label="字典值" min-width="120" />
      <el-table-column prop="operate" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="doEdit(scope.row)">修改</el-button>
          <el-button type="text" @click="doDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Page
      :total="totalNumber"
      @handleCurrentChangeSub="handleCurrentChangeFun"
    />

    <!-- 新增/编辑元器件 -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="60px"
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        ref="compForm"
        :model="compForm"
        label-width="120px"
        label-position="right"
        :rules="formRules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="字典类型" prop="dictionaryType">
              <el-select v-model="compForm.dictionaryType" placeholder="分类">
                <el-option
                  v-for="dict in dicts"
                  :key="dict.Nub"
                  :value="dict.Nub"
                  :label="dict.label"
                  >{{ dict.label }}</el-option
                >
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="字典值" prop="dictionaryValue">
              <el-input
                v-model="compForm.dictionaryValue"
                placeholder="字典值"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div v-if="!isView" slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="saveDialogForm()"
          >确 定</el-button
        >
      </div>
      <div v-else slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getDictList, dictSave, deleteDict } from "@/api/dict";
import { dict } from "@/utils/dic";
const filePrefix = process.env.VUE_APP_BASE_API + "system/download?fileId=";
const txtPrefix =
  process.env.VUE_APP_BASE_API + "system/onlinePreviewTxtFile?filePath=";
export default {
  name: "Flow",
  components: {},
  data() {
    return {
      searchForm: {},
      fileList: [],
      formLabelWidth: "100px",
      tableLoading: false,
      tableData: [],
      totalNumber: 0,
      dialogTitle: "",
      dialogFormVisible: false,
      compForm: {},
      formRules: {
        dictionaryType: [
          {
            required: true,
            message: "请选择字典类型",
            trigger: "change"
          }
        ],
        dictionaryValue: [
          {
            required: true,
            message: "请输入字典值",
            trigger: "blur"
          }
        ]
      },
      loading: false,
      tmpFileList: [],
      isView: false,
      dicts: [],
      page: 1
    };
  },
  computed: {},
  watch: {
    dialogFormVisible(v) {
      if (!v) {
        this.fileList = [];
        this.uploadedFileList = [];
        this.loading = false;
      }
    }
  },
  mounted() {
    this.getDictList();
    this.getDict();
  },
  methods: {
    handleCurrentChangeFun(page) {
      this.page = page;
      this.getDictList();
    },
    getDictList() {
      this.tableLoading = true;
      getDictList({ ...this.searchForm })
        .then(res => {
          this.tableLoading = false;
          this.tableData = res.data.records;
          this.totalNumber = res.data.total;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    doAdd() {
      this.dialogTitle = "新增字典数据";
      this.dialogFormVisible = true;
      this.$refs["compForm"] && this.$refs["compForm"].resetFields();
      this.compForm = {};
    },
    doDelete(row) {
      this.$confirm(`您确认删除 , 是否继续?`, "提示", {
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: "warning"
      }).then(() => {
        deleteDict(row.id).then(() => {
          this.$message.success("删除成功");
          this.getDictList();
        });
      });
    },
    doEdit(row) {
      this.dialogFormVisible = true;
      this.dialogTitle = "修改字典数据";
      this.$set(this.compForm, "id", row.id);
      this.$set(this.compForm, "dictionaryType", row.dictionaryType);
      this.$set(this.compForm, "dictionaryValue", row.dictionaryValue);
    },

    handlePreview(file) {
      if (file.status == "success") {
        if (file.name.endsWith(".txt")) {
          window.open(txtPrefix + file.path);
        } else {
          window.open(filePrefix + file.id);
        }
      }
    },
    handleChange(file, fileList) {
      this.tmpFileList = fileList;
    },
    saveDialogForm() {
      this.$refs.compForm.validate(valid => {
        if (valid) {
          var data = { ...this.compForm };
          dictSave(data)
            .then(res => {
              this.dialogFormVisible = false;
              this.getDictList();
            })
            .catch(() => {});
        }
      });
    },
    // 获取字典类型
    getDict() {
      let dicts = [];
      for (const key in dict) {
        if (dict.hasOwnProperty(key)) {
          const element = dict[key];
          dicts.push(element);
        }
      }
      this.dicts = dicts;
    },
    /**重置表单 */
    resetSearch() {
      this.page = 1;
      this.searchForm = {};
      this.getDictList();
    }
  },
  filters: {
    dictionaryTypeFilter(val) {
      let dicts = [];
      let result = "";
      for (const key in dict) {
        if (dict.hasOwnProperty(key)) {
          const element = dict[key];
          dicts.push(element);
        }
      }
      dicts.forEach(item => {
        if (val === item.Nub) result = item.label;
      });
      return result;
    }
  }
};
</script>
<style lang="scss" scoped>
.el-upload-list__item {
  border: 1px solid gray;
}
.el-dialog {
  width: calc(100vw - 220px) !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  .el-dialog__body {
    height: calc(100vh - 175px) !important;
    overflow: auto;
  }
  .upload-demo-none {
    .el-upload {
      display: none;
    }
  }
}
</style>

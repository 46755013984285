import request from "@/utils/request";
/**
 * 字典列表
 * @param {} params
 */
export function getDictList(params) {
  return request({
    url: "/physicalDictionary/page",
    method: "GET",
    params
  });
}
/**
 * 保存、编辑字典
 * @param {} params
 */
export function dictSave(data) {
  return request({
    url: "/physicalDictionary/save",
    method: "POST",
    data
  });
}
/**
 * 删除字典
 * @param {} params
 */
export function deleteDict(data) {
  return request({
    url: "/physicalDictionary/ids/" + data,
    method: "DELETE"
  });
}
